import { defineStore } from 'pinia'
import ElMessage from 'element/ElMessage'
import { getAlerts, updateAlerts } from 'lib/api/modules/streamer/freemium/alerts'
import { clearCache } from 'lib/api/modules/streamer/freemium/cache'
import { useRootLocale } from 'lib/helpers/useLocale'
import { IdType } from 'lib/types/base-types'
import { IFreemiumAlerts } from 'lib/types/streamer-freemium/freemium-alerts'

interface State {
  isFetchingData: boolean
  alerts: IFreemiumAlerts | null
  locale: any
}

export const useFreemiumAlertsStore = defineStore({
  id: 'freemiumAlerts',

  state: (): State => ({
    isFetchingData: false,
    alerts: null,
    locale: useRootLocale('views.streamer.freemium.alerts'),
  }),

  actions: {
    async fetchAlerts () {
      this.isFetchingData = true
      const res = await getAlerts()
      this.alerts = res.data
      this.isFetchingData = false
    },

    async updateAlerts (model: IFreemiumAlerts, streamerId: IdType) {
      const res = await updateAlerts(model)
      if (res.status) {
        ElMessage.success(this.locale?.statusMessage?.success)
        await clearCache(streamerId)
      }
      else {
        ElMessage.error(this.locale?.statusMessage?.fail)
      }
    },
  },
})
