import { defineStore } from 'pinia'

import { clearCache } from 'lib/api/modules/streamer/freemium/cache'
import {
  deleteProfileBanner,
  getProfile,
  getProfileData,
  setProfileAboutData,
  setProfileBanner,
  setProfileColorScheme,
  setProfileGearsData,
  updateLinkName,
  updateProfile,
  updateTelegram,
} from 'lib/api/modules/streamer/freemium/profile'
import { IdType } from 'lib/types/base-types'
import { FreemiumTheme } from 'lib/types/freemium-theme'
import { LocaleIdType } from 'lib/types/locale'
import { IFreemiumProfile, IFreemiumProfileGear } from 'lib/types/streamer-freemium/freemium-profile'

export const ABOUT_MAX_LENGTH = 1000

interface State {
  isBootsraped: boolean
  isLoadingData: boolean
  profile: null | IFreemiumProfile
}

export const useFreemiumProfileStore = defineStore({
  id: 'freemiumGear',

  state: (): State => ({
    isBootsraped: false,
    isLoadingData: false,
    profile: null,
  }),

  actions: {
    async fetchProfile () {
      this.isLoadingData = true
      try {
        const platformRes = await getProfileData()
        const freemiumRes = await getProfile()

        this.profile = {
          ...platformRes.data,
          // generated_about: freemiumRes.data.generated_about,
          generated_about: {
            text: '',
            confirmed: false,
            edited: false,
          },
          social_links: freemiumRes.data.social_links,
          linkName: freemiumRes.data.linkName,
          telegram: freemiumRes.data.telegram,
        }

        this.profile.color_schema = platformRes.data.color_schema || 'theme-light'
        this.isBootsraped = true
      }
      finally {
        this.isLoadingData = false
      }
    },

    async saveGears (gears: IFreemiumProfileGear[], streamerId: IdType) {
      this.isLoadingData = true
      try {
        await setProfileGearsData(gears)
        await this.fetchProfile()
        await clearCache(streamerId)
      }
      finally {
        this.isLoadingData = false
      }
    },

    async saveLinkName ({ linkName, streamerId, locale }: {linkName: string; streamerId: IdType; locale: LocaleIdType}) {
      if (!this.profile) {
        return
      }

      this.isLoadingData = true
      try {
        // this.profile.about = about
        await updateLinkName(linkName, streamerId, locale)
        await this.fetchProfile()
        await clearCache(streamerId)
      }
      finally {
        this.isLoadingData = false
      }
    },

    async saveTelegram ({ telegram, streamerId }: {telegram: string; streamerId: IdType }) {
      if (!this.profile) {
        return
      }

      this.isLoadingData = true
      try {
        // this.profile.about = about
        await updateTelegram(telegram, streamerId)
        await this.fetchProfile()
        await clearCache(streamerId)
      }
      finally {
        this.isLoadingData = false
      }
    },

    async saveAbout (about: string, streamerId: IdType) {
      if (!this.profile) {
        return
      }

      this.isLoadingData = true
      try {
        // this.profile.about = about
        await setProfileAboutData(about.slice(0, ABOUT_MAX_LENGTH))
        await clearCache(streamerId)
      }
      finally {
        this.isLoadingData = false
      }
    },

    async saveColorScheme (colorSchema: FreemiumTheme, streamerId: IdType) {
      if (!this.profile) {
        return
      }

      this.isLoadingData = true
      try {
        this.profile.color_schema = colorSchema
        await setProfileColorScheme(colorSchema)
        await clearCache(streamerId)
      }
      finally {
        this.isLoadingData = false
      }
    },

    async saveBanner (banner: string, streamerId: IdType) {
      if (!this.profile) {
        return
      }

      this.isLoadingData = true
      try {
        this.profile.banner = banner
        await setProfileBanner(banner)
        await clearCache(streamerId)
      }
      finally {
        this.isLoadingData = false
      }
    },

    async deleteBanner (streamerId: IdType) {
      if (!this.profile) {
        return
      }

      this.isLoadingData = true
      try {
        this.profile.banner = null
        await deleteProfileBanner()
        await clearCache(streamerId)
      }
      finally {
        this.isLoadingData = false
      }
    },

    async saveSocialLinks (socialLinks: IFreemiumProfile['social_links']) {
      if (!this.profile) {
        return
      }

      this.isLoadingData = true
      try {
        if (!this.profile) return

        this.profile.social_links = socialLinks
        const profileData = this.getFreemiumProfileData()

        if (profileData) {
          await updateProfile(profileData)
        }
      }
      finally {
        this.isLoadingData = false
      }
    },

    updateStoreAboutData (about: string, generatedAbout: IFreemiumProfile['generated_about']) {
      if (!this.profile) return
      this.profile = {
        ...this.profile,
        about,
        generated_about: {
          ...generatedAbout,
          text: generatedAbout.text.slice(0, ABOUT_MAX_LENGTH),
        },
      }
    },

    async saveGeneratedAbout (generatedAbout: IFreemiumProfile['generated_about']) {
      if (!this.profile) {
        return
      }

      this.isLoadingData = true
      try {
        if (!this.profile) return

        // this.profile.generated_about = generatedAbout
        const profileData = this.getFreemiumProfileData()

        if (profileData) {
          await updateProfile(profileData)
        }
      }
      finally {
        this.isLoadingData = false
      }
    },

    getFreemiumProfileData () {
      if (!this.profile) return null

      return {
        generated_about: this.profile?.generated_about,
        social_links: this.profile?.social_links,
      }
    },
  },
})
