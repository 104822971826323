<template>
  <div
    class="closable-section"
    :class="{'closable-section-collapsed' : collapsed}"
  >
    <button
      v-show="$isMobile"
      type="button"
      class="closable-section__toggle"
      :class="{'closable-section__toggle-no-border': noBorder}"
      @click="collapseSection"
    >
      <SvgIcon
        :name="collapsed? 'collapse-plus' : 'collapse-minus'"
        width="24"
        height="24"
      />
    </button>

    <slot />
  </div>
</template>

<script>
export default {
  props: {
    noBorder: VueTypes.boolean,
  },
  data: () => ({
    collapsed: false,
  }),
  methods: {
    collapseSection () {
      this.collapsed = !this.collapsed
    },
  },
}
</script>

<style lang="scss" scoped>
.closable-section {
  position: relative;
  margin-bottom: 40px;

  @media (max-width: $--tablet-portrait) {
    /* padding-bottom: 32px; */
    margin-bottom: 32px;
  }

  ::v-deep {
      .dashboard-section {
        padding-bottom: 0;
      }
    }

  &-collapsed {
    ::v-deep {
      .dashboard-section__right {
        display: none;
      }
    }
  }

  &__toggle {
    position: absolute;
    top: 28px;
    right: 0;
    z-index: 5;
    background-color: transparent;
    border: none;

    &-no-border {
      top: -4px;
    }
    .svg-icon {
      fill: #1A1A1A;
    }
  }
}
</style>
