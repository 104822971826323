export enum StreamerSocial {
  DISCORD = 'Discord',
  FACEBOOK = 'Facebook',
  INSTAGRAM = 'Instagram',
  TELEGRAM = 'Telegram',
  TWITTER = 'Twitter',
  VK = 'Vkontakte',
  YOUTUBE = 'Youtube',
  TIKTOK = 'TikTok',
}
