import { defineStore } from 'pinia'

import { clearCache } from 'lib/api/modules/streamer/freemium/cache'
import { createGoal, createPoll, disableGoal, disablePoll, getSetup, getStats, updateGoal, updatePoll } from 'lib/api/modules/streamer/freemium/setup'
import { getWidget } from 'lib/api/modules/streamer/freemium/widget'
import { useRootLocale } from 'lib/helpers/useLocale'
import { IdType } from 'lib/types/base-types'
import { IFreemiumGoal, IFreemiumGoalModel, IFreemiumPoll, IFreemiumPollModel, IFreemiumSetup } from 'lib/types/streamer-freemium/freemium-setup'
import { IFreemiumStats } from 'lib/types/streamer-freemium/freemium-supporters'
import { IFreemiumWidget } from 'lib/types/streamer-freemium/freemium-widget'

import ElMessage from 'element/ElMessage'
import { getProfile } from 'lib/api/modules/streamer/freemium/profile'
import { get } from 'lib/api/modules/streamer/profile'


interface State {
  isBootsraped: boolean
  isLoadingData: boolean
  currentGoal: IFreemiumGoal | null
  goalFormOpened: boolean
  currentPoll: IFreemiumPoll | null
  defaultGoalId: number | null
  widget: IFreemiumWidget | null
  stats: IFreemiumStats
  pollFormOpened: boolean
  income: {
    month: number
    total: number
  }
  locale: any
}

export const useFreemiumSetupStore = defineStore({
  id: 'freemiumSetup',

  state: (): State => ({
    isBootsraped: false,
    isLoadingData: false,
    currentGoal: null,
    defaultGoalId: null,
    goalFormOpened: false,
    currentPoll: null,
    widget: null,
    pollFormOpened: false,
    stats: {
      avg_cpm: 0,
      impressions: 0,
      revenue_day: 0,
      revenue_month: 0,
      is_premium: false,
    },
    income: {
      month: 0,
      total: 0,
    },
    locale: useRootLocale('views.streamer.freemium.setup'),
  }),

  actions: {
    async fetchData (streamerId: number) {
      if (this.goalFormOpened || this.pollFormOpened) {
        return
      }

      this.isLoadingData = true
      try {
        let setup = null
        let stats = null
        stats = await getStats()
        setup = await getSetup()
        
        this.setSetupData(setup.data)
        this.setStatsData(stats.data)
        this.isBootsraped = true

        const widget = await getWidget(
          streamerId,
          this.currentGoal?.id || this.defaultGoalId,
          this.currentPoll ? this.currentPoll?.id : null,
        )
        this.setWidgetData(widget, setup?.data.goal as IFreemiumGoal)
      }
      finally {
        this.isLoadingData = false
      }
    },
    setStatsData (data: IFreemiumStats) {
      this.stats = data
    },

    setSetupData (data: IFreemiumSetup) {
      if (!Array.isArray(data.poll)) {
        this.currentPoll = data.poll
      }
      if (!Array.isArray(data.goal)) {
        this.currentGoal = data.goal
      }
      if (data.default_goal?.id) {
        this.defaultGoalId = data.default_goal.id
      }
      // if (data.income) {
      //   this.income = data.income
      // }
    },

    setWidgetData (data: IFreemiumWidget, goal: IFreemiumGoal | null) {
      this.widget = { ...data }
    },

    toggleGoalForm (state: boolean) {
      this.goalFormOpened = state
    },

    async createGoal (data: IFreemiumGoalModel, streamerId: IdType) {
      const res = await createGoal(data)
      this.currentGoal = res.goal
      await clearCache(streamerId)
    },

    async updateGoal (id: number, data: IFreemiumGoalModel, streamerId: IdType) {
      const res = await updateGoal(id, data)

      if (res.status) {
        ElMessage.success(this.locale?.goalForm?.statusMessage?.success)
        if (this.currentGoal) {
          this.currentGoal = { ...this.currentGoal, ...data }
        }
        await clearCache(streamerId)
      }
      else {
        ElMessage.error(this.locale?.goalForm?.statusMessage?.fail)
      }
    },

    async deleteGoal (streamerId: IdType) {
      if (this.currentGoal) {
        this.isLoadingData = true
        await disableGoal(this.currentGoal.id)
        this.currentGoal = null
        this.isLoadingData = false
        await clearCache(streamerId)
      }
    },

    async createPoll (data: IFreemiumPollModel, streamerId: IdType) {
      const res = await createPoll(data)
      this.currentPoll = res.poll
      await clearCache(streamerId)
    },

    async updatePoll (id: number, data: IFreemiumPollModel, streamerId: IdType) {
      const res = await updatePoll(id, data)

      if (res.status) {
        ElMessage.success(this.locale?.pollForm?.statusMessage?.success)
        if (this.currentPoll) {
          this.currentPoll = { ...this.currentPoll, ...data }
        }
        await clearCache(streamerId)
      }
      else {
        ElMessage.error(this.locale?.pollForm?.statusMessage?.fail)
      }
    },

    togglePollForm (state: boolean) {
      this.pollFormOpened = state
    },

    async deletePoll (streamerId: IdType) {
      if (this.currentPoll) {
        this.isLoadingData = true
        await disablePoll(this.currentPoll.id)
        this.currentPoll = null
        this.isLoadingData = false
        await clearCache(streamerId)
      }
    },

    updatePollTimer (timer: number) {
      if (!this.currentPoll) {
        return
      }
      this.currentPoll.remainder_duration = timer
    },

    updatePollVotes (votes: number) {
      if (!this.currentPoll) {
        return
      }
      this.currentPoll.max_votes = votes
    },
  },
})
