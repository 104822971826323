import { render, staticRenderFns } from "./SetupLinkNameSection.vue?vue&type=template&id=668ea93a&scoped=true&"
import script from "./SetupLinkNameSection.vue?vue&type=script&setup=true&lang=ts&"
export * from "./SetupLinkNameSection.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./SetupLinkNameSection.vue?vue&type=style&index=0&id=668ea93a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "668ea93a",
  null
  
)

export default component.exports