import { defineStore } from 'pinia'

import { createPost, deletePost, getPosts, loginLinkUser, registerLinkUser, updatePost } from 'lib/api/modules/streamer/freemium/posts'
import { setLinkAdminToken, UPLIFY_ADMIN_TOKEN } from 'lib/helpers/authToken'
import { IFreemiumUserPost } from 'lib/types/streamer-freemium/freemium-posts'

export const POST_CONTENT_MAX_LENGTH = 1024

export enum SocialEmbed {
  REDDIT = 'reddit',
  YOUTUBE = 'youtube',
  X = 'x',
  SPOTIFY = 'spotify',
  INSTAGRAM = 'instagram',
  TELEGRAM = 'telegram',
}

interface State {
  isFetchingData: boolean
  posts: {
    perPage: number
    page: number
    total: null | number
    data: null | IFreemiumUserPost[]
  }
  post: IFreemiumUserPost
  sidebarVisible: boolean
  isAuthorized: boolean
}

export const useFreemiumPostsStore = defineStore({
  id: 'freemiumPosts',

  state: (): State => ({
    isFetchingData: false,
    posts: {
      perPage: 25,
      page: 1,
      total: null,
      data: null,
    },
    post: {
      id: null,
      attributes: {
        content: '',
        embed: '',
      },
    },
    sidebarVisible: false,
    isAuthorized: false,
  }),

  actions: {
    async getPosts () {
      this.isFetchingData = true
      try {
        const res = await getPosts(this.posts.page)

        this.posts.data = res.data
        this.posts.page = res.meta.pagination.page
        this.posts.perPage = res.meta.pagination.pageSize
        this.posts.total = res.meta.pagination.total
      }
      catch {

      }
      finally {
        this.isFetchingData = false
      }
    },

    async submitPost () {
      try {
        if (this.post.id) {
          await updatePost(this.post.id, this.post.attributes)
        }
        else {
          await createPost(this.post.attributes)
        }
        this.getPosts()
      }
      catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
    },

    async deletePost (id: number) {
      try {
        await deletePost(id)
        this.getPosts()
      }
      catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
    },

    async showSidebar () {
      this.post = {
        id: null,
        attributes: {
          content: '',
          embed: '',
        },
      }
      this.sidebarVisible = true
    },

    async authorize (streamerId: number) {
      const token = localStorage.getItem(UPLIFY_ADMIN_TOKEN)

      if (token) {
        setLinkAdminToken(token)
        return
      }

      const prefix = process.env.NODE_ENV === 'development' ? 'dev_' : ''

      const credentials = {
        username: `uplify_${prefix}user_${streamerId}`,
        email: `uplify_${prefix}user_${streamerId}@uplify.app`,
        password: `uplify_${prefix}user_${streamerId}@uplify.app`,
      }

      try {
        const res = await loginLinkUser({
          identifier: credentials.email,
          password: credentials.password,
        })

        if (!res.jwt) {
          throw new Error('Error loggin user')
        }

        setLinkAdminToken(res.jwt)

        this.isAuthorized = true
      }
      catch (err) {
        // eslint-disable-next-line no-console
        console.warn('User not found')

        try {
          const res = await registerLinkUser(credentials)

          if (!res.jwt) {
            throw new Error('Error registering user')
          }

          setLinkAdminToken(res.jwt)

          this.isAuthorized = true
        }
        catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
    },
  },
})
