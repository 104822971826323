import moment from 'moment'
import { defineStore } from 'pinia'

import { getSupportersStatistics, getTopSupporters, getTopSupportersByPeriod } from 'lib/api/modules/streamer/freemium/supporters'
import { IStreamerFreemiumTopSupporters } from 'lib/types/payloads/streamer-freemium'
import { IFreemiumTopSupporter } from 'lib/types/streamer-freemium/freemium-supporters'

export enum SupportersTab {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  WEEK = 'week',
  MONTH = 'month',
  ALLTIME = 'alltime',
  PERIOD = 'period',
}

interface State {
  isFetchingData: boolean
  supporters: null | IStreamerFreemiumTopSupporters
  supportersCount: null | number
  points: null | number
  period: {
    supporters: null | IFreemiumTopSupporter[]
    date: null | [Date, Date]
  }
  currentList: SupportersTab
}

export const useFreemiumSupportersStore = defineStore({
  id: 'freemiumSupporters',

  state: (): State => ({
    isFetchingData: false,
    supporters: null,
    supportersCount: null,
    points: null,
    period: {
      supporters: null,
      date: null,
    },
    currentList: SupportersTab.TODAY,
  }),

  actions: {
    async fetchData (streamerId: number) {
      this.isFetchingData = true

      try {
        const [supporters, statistic] = await Promise.all([
          getTopSupporters(streamerId),
          getSupportersStatistics(streamerId),
        ])
        this.supporters = supporters
        this.supportersCount = statistic.supporters.count
        this.points = statistic.points.monthly
      }
      catch {
        this.supporters = null
      }
      finally {
        this.isFetchingData = false
      }
    },

    async fetchPeriod (streamerId: number) {
      if (!this.period.date) {
        return
      }

      this.isFetchingData = true

      try {
        const supporters = await getTopSupportersByPeriod({
          streamerId,
          start: moment(this.period.date[0]).format('DD-MM-YYYY'),
          end: moment(this.period.date[1]).format('DD-MM-YYYY'),
        })

        this.period.supporters = supporters.period
      }
      catch {
        this.period.supporters = null
      }
      finally {
        this.isFetchingData = false
      }
    },
  },
})
