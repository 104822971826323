import { UplifyAdminApi } from 'lib/api/uplify-admin-instance'
import { IResponseData } from 'lib/types/response'
import { IFreemiumAuthResponse, IFreemiumLoginCredentials, IFReemiumPosts, IFreemiumRegisterCredentials, IFreemiumUserPost } from 'lib/types/streamer-freemium/freemium-posts'

export const loginLinkUser = async (data: IFreemiumLoginCredentials) => {
  return UplifyAdminApi.post<IFreemiumAuthResponse>('auth/local', data)
    .then(res => res)
}

export const registerLinkUser = async (data: IFreemiumRegisterCredentials) => {
  return UplifyAdminApi.post<IFreemiumAuthResponse>('auth/local/register', data)
    .then(res => res)
}

export const getPosts = async (page: number) => {
  return UplifyAdminApi.get<IFReemiumPosts>('posts', {
    sort: 'createdAt:desc',
    'pagination[page]': page,
  })
    .then(res => res)
}

export const createPost = async (data: IFreemiumUserPost['attributes']) => {
  return UplifyAdminApi.post<IResponseData<IFreemiumUserPost>>('posts', { data })
    .then(res => res)
}

export const updatePost = async (postId: number, data: IFreemiumUserPost['attributes']) => {
  return UplifyAdminApi.put<IResponseData<IFreemiumUserPost>>(`posts/${postId}`, { data })
    .then(res => res)
}

export const deletePost = async (postId: number) => {
  return UplifyAdminApi.delete(`posts/${postId}`)
    .then(res => res)
}
