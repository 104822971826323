import { Api, FreemiumApi } from 'lib/api'
import { LocaleIdType } from 'lib/types/locale'
import { IResponseData, IStatus } from 'lib/types/response'
import { IFreemiumProfile, IFreemiumProfileGear } from 'lib/types/streamer-freemium/freemium-profile'

export const getProfileData = async () => {
  return Api
    .get<IResponseData<IFreemiumProfile>>('streamer/extend')
    .then(res => res)
}

export const getProfileGearsData = async () => {
  return Api
    .get<IResponseData<IFreemiumProfileGear[]>>('streamer/extend/gears')
    .then(res => res)
}

export const setProfileGearsData = async (gears: IFreemiumProfileGear[]) => {
  return Api
    .post<IStatus>('streamer/extend/gears/update', { gears })
    .then(res => res)
}

export const setProfileAboutData = async (about: string) => {
  return Api
    .post<IStatus>('streamer/extend/about/update', { about })
    .then(res => res)
}

export const setProfileColorScheme = async (colorSchema: string) => {
  return Api
    .post<IStatus>('streamer/extend/color_schema/update', { color_schema: colorSchema })
    .then(res => res)
}

export const setProfileBanner = async (banner: string) => {
  return Api
    .post<IStatus>('streamer/extend/banner/update', { banner })
    .then(res => res)
}

export const deleteProfileBanner = async () => {
  return Api
    .post<IStatus>('streamer/extend/banner/delete')
    .then(res => res)
}

export const getProfile = async () => {
  return await FreemiumApi.get<IResponseData<IFreemiumProfile>>('streamer/profile')
}

export const updateProfile = async (data: Pick<IFreemiumProfile, 'generated_about' | 'social_links'>) => {
  return await FreemiumApi.post('streamer/profile', data)
}

export const updateLinkName = async (linkName: string, streamerId: number, locale: LocaleIdType) => {
  return await FreemiumApi.post(
    `streamer/set-name?streamerId=${streamerId}`,
    { locale, linkName },
    { showMessage: false },
  )
}

export const updateTelegram = async (telegram: string, streamerId: number) => {
  return await FreemiumApi.post(
    `streamer/telegram/channel?streamerId=${streamerId}`,
    { telegram },
    { showMessage: false },
  )
}
